import styled from 'styled-components';
import noShowBg from '../../images/noshow-bg.png';
import { font } from '../../styles/atoms/typography';

export const NoShowStyles = styled.div`
  background-color: #1d2030;
  color: var(--white);
  padding-top: 170px;
  padding-bottom: 96px;
  background-image: url(${noShowBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 876px) {
    padding-top: 138px;
  }

  .fs-16 {
    font-size: 16px;
  }

  input {
    ${font('text', 'sm', '400')}
    color: #667085;
    background: var(--white);
    border: 1px solid #c0c3d1;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  button {
    ${font('display', 'xs', '700')}
    color: var(--white);
    background-color: #00a081;
    border-radius: 6px;
    margin-bottom: 32px;
  }

  .header-wrapper {
    text-align: center;
    max-width: 770px;
    margin: 0 auto;
    margin-bottom: 64px !important;

    .header {
      ${font('display', 'xxl', '700')}
      color: var(--white);

      @media (max-width: 768px) {
        ${font('display', 'xl', '700')}
      }
    }

    .sub-head {
      font-size: 22.5px;
      line-height: 150%;
      letter-spacing: -0.27px;
      color: #d8dae6;
      margin-bottom: 0;

      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
  }

  .form-title {
    ${font('display', 'md', '700')}
    text-align: center;
    margin-bottom: 32px;

    @media (max-width: 575px) {
      font-size: 22.5px;
    }
  }

  .noshow-calculator {
    padding: 48px;
    max-width: 570px;
    min-height: 607px;
    color: #1d2030;
    background: var(--white);
    box-shadow: 0 24px 48px -12px rgba(19, 48, 205, 0.25);
    border-radius: 12px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 48px 32px;
    }

    @media (max-width: 575px) {
      padding: 48px 24px;
      min-height: fit-content;
    }

    .form-content {
      .form-group {
        margin-bottom: 32px;
      }

      label {
        font-weight: 700;
        font-size: 22.5px;
        line-height: 110%;
        letter-spacing: -0.27px;
        color: #1d2030;
        margin-bottom: 24px;

        @media (max-width: 575px) {
          font-size: 16px;
        }
      }

      input {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.16px;
        color: #667085;
      }
    }

    .notice {
      max-width: 474px;

      p {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.16px;
        color: #6f7283;
        margin-bottom: 0;

        @media (max-width: 575px) {
          font-size: 14.4px;
        }
      }
    }
  }

  .completed-calc {
    padding: 48px;
    max-width: 570px;
    min-height: 525px;
    color: #1d2030;
    text-align: center;
    background: var(--white);
    box-shadow: 0 24px 48px -12px rgba(19, 48, 205, 0.25);
    border-radius: 12px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 48px 32px;
    }

    @media (max-width: 575px) {
      padding: 48px 24px;
      min-height: fit-content;
    }

    img {
      height: 47px;
      margin-bottom: 16px;
    }

    .title-wrapper {
      text-align: center;
      margin-bottom: 24px;

      .form-title {
        margin-bottom: 16px;
      }

      .sub-title {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.16px;
        color: #6f7283;
        margin-bottom: 0;
      }
    }

    .form-content {
      .input-row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        .input-wrapper {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: 100%;

          &:first-of-type:not(:last-of-type) {
            margin-right: 16px;
          }

          .form-group {
            margin-bottom: 24px !important;
          }

          label {
            font-weight: 500;
            font-size: 14.4px;
            line-height: 150%;
            color: #4d5061;

            &:after {
              content: '*';
              color: var(--critical-color);
              vertical-align: middle;
            }
          }
        }
      }

      button {
        margin-bottom: 0;
      }
    }
  }

  .reports-wrapper {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 768px) {
      padding: 0 20px;
    }

    .kicker {
      font-weight: 700;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: var(--white);
      margin-bottom: 16px;
    }

    .amount {
      font-weight: 700;
      font-size: 72px;
      line-height: 110%;
      letter-spacing: -0.3px;
      color: var(--white);
      margin-bottom: 0;

      @media (max-width: 575px) {
        font-size: 62px;
      }
    }

    .result-cards {
      display: flex;
      justify-content: space-between;
      max-width: 858px;
      margin: 72px 0;

      @media (max-width: 876px) {
        flex-direction: column;
        align-items: center;
      }

      .report-card {
        display: flex;
        flex-direction: column;
        min-width: 270px;
        background: var(--white);
        border-radius: 15px;
        padding: 25px;

        @media (max-width: 876px) {
          min-width: 378px;
          margin-bottom: 16px;
        }

        @media (max-width: 575px) {
          min-width: 270px;
        }

        p {
          margin-bottom: 0;
        }

        .loss-term {
          font-weight: 700;
          font-size: 12px;
          line-height: 120%;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #1d2030;
          margin-bottom: 16px;
        }

        .value {
          font-weight: 700;
          font-size: 35.16px;
          line-height: 110%;
          letter-spacing: -0.3px;
          color: #d93030;
        }
      }
    }

    .testimonials {
      .buttons {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 32px;
        margin: 0px auto;
      }

      .quote {
        font-weight: 700;
        font-size: 22.5px;
        line-height: 150%;
        letter-spacing: -0.27px;
        color: var(--white);

        p {
          margin-bottom: 12px;
        }
      }

      .name {
        font-size: 14.4px;
        line-height: 150%;
        color: var(--white);
        margin-bottom: 32px;
      }

      .btn-restart {
        font-weight: 700;
        font-size: 14px;
        line-height: 110%;
        letter-spacing: -0.16px;
        color: #d8dae6;
        margin-bottom: 0;
        background-color: transparent !important;
      }
    }

    .disclaimer {
      font-size: 14.4px;
      line-height: 150%;
      color: #c0c3d1;
      margin-top: 72px;
    }
  }
`;
